/**
 * 搜索头
 */
export const searchHeaderOptions = [
  {
    field: "company_id",
    label: "企业名称",
    type: "select-data-search",
    defaultValue: null,
    iw: 160,
    props: {
      placeholder: "请输入企业名称"
    },
    options: []
  },
  {
    field: "external_id",
    label: "关联运单号",
    type: "input",
    iw: 160,
    props: {
      placeholder: "请输入运单号"
    },
    rules: { min: 2, message: "最少输入两个字符", trigger: "blur" }
  },
  {
    type: "select",
    field: "account_type",
    label: "账户类型",
    iw: 160,
    defaultValue: null,
    options: []
  },
  {
    type: "select",
    field: "service_group",
    label: "调用服务",
    iw: 160,
    defaultValue: null,
    options: []
  },
  {
    field: ["start_time", "end_time"],
    type: "dateRange",
    label: "操作时间",
    iw: 320
  }
];
