import { buildApi } from "@/utils/ajax";

const basicApi = buildApi("/portal/ops");

// 服务管理列表
export const useAccountLogApi = basicApi({
  url: "/account_log/query",
  withCredentials: true
});

// 服务管理列表导出
export const useAccountLogExportApi = basicApi({
  url: "/account_log/export",
  withCredentials: true,
  responseType: "blob",
  meta: { isTransformRequestResult: false }
});
