<template>
  <div class="page-main-box" id="serviceOrderBox">
    <!-- 有数据 -->
    <div class="pt-24 pl-16 pr-16">
      <!-- 搜索头 -->
      <search-header :schemaData="schema" @getDataList="handleFormSearch" />

      <!-- 列表 -->
      <div :style="{ height: tableHeight + 'px' }">
        <p-table
          id="service-order-table"
          :dataSource="tableDataSource"
          height="auto"
        >
          <p-t-column type="seq" width="60" title="序号" fixed="left" />
          <p-t-column field="company_name" min-width="233" title="客户名称" />
          <p-t-column field="external_id" min-width="162" title="关联运单号" />
          <p-t-column
            field="account_type_desc"
            min-width="107"
            title="账户类型"
          ></p-t-column>
          <p-t-column
            field="service_group_desc"
            min-width="107"
            title="调用服务"
          />
          <p-t-column
            field="service_provider_name"
            min-width="107"
            title="服务商"
          />
          <p-t-column
            field="money"
            min-width="107"
            title="销售价"
            align="right"
          >
            <template v-slot="{ row }">
              <span>{{ (row.money / 100).toFixed(2) }}</span>
            </template>
          </p-t-column>
          <p-t-column field="create_time" min-width="170" title="调用时间">
            <template v-slot="{ row }">
              <span>{{
                formatToDateTime(row.create_time, "yyyy-MM-dd hh:mm:ss")
              }}</span>
            </template>
          </p-t-column>

          <template #footer>
            <a-button class="mt-12 ml-32" @click="handleExport" type="primary"
              >导出</a-button
            >
          </template>
        </p-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRaw, onMounted } from "vue";
import { useRoute } from "vue-router";
import { Table } from "@wlhy-web-components/common";
import { searchHeaderOptions } from "./Constants";
import { useAccountLogApi, useAccountLogExportApi } from "@/apis/order";
import {
  useEnumAccountTypeApi,
  useEnumServiceApi,
  useCompanyEnumApi
} from "@/apis/enums";
import useSearchForm from "@/hooks/UseSearchForm";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import { exportExcel } from "@/hooks/useExport";
import useSearch from "@/hooks/useSearch";
import useTableHeight from "@/hooks/useTableHeight";
import useDebounce from "@/hooks/useDebounce";

export default {
  name: "service-order",
  components: {},
  setup() {
    const route = useRoute();
    const accountLogApi = useAccountLogApi();
    const accountLogExportApi = useAccountLogExportApi();
    const enumAccountTypeApi = useEnumAccountTypeApi();
    const enumServiceApi = useEnumServiceApi();
    const companyEnumApi = useCompanyEnumApi();

    // table高度
    const tableHeight = ref();

    let schemaList = searchHeaderOptions;
    let enumeration = searchHeaderOptions.find(v => v.field === "company_id");

    (async () => {
      let [, account] = await $await(enumAccountTypeApi());
      schemaList = useSearch(searchHeaderOptions, account, "account_type");
      let [, service] = await $await(enumServiceApi({ skip: "GENERIC" }));
      schemaList = useSearch(searchHeaderOptions, service, "service_group");
      enumeration.props.onSearch = handleSearch;
    })();

    const { filter, handleFormSearch } = useSearchForm(schemaList);

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...toRaw(filter.value),
          account_id: filter.value.click ? "" : route.query.id,
          start_time: filter.value.start_time || route.query.time,
          end_time: filter.value.end_time || route.query.time,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const [, res] = await $await(accountLogApi(data));
        return useTableDataRecords(res);
      }
    });

    // 导出
    const handleExport = async () => {
      const data = {
        ...toRaw(filter.value),
        account_id: filter.value.click ? "" : route.query.id,
        start_time: filter.value.start_time || route.query.time,
        end_time: filter.value.end_time || route.query.time
      };
      const [, res] = await $await(accountLogExportApi(data));
      exportExcel(res, "服务订单");
    };

    const handleSearch = useDebounce(async value => {
      const [, res] = await $await(
        companyEnumApi({ name: value, page: 1, size: 100 })
      );
      useSearch(searchHeaderOptions, res, "company_id", false);
    }, 500);

    handleSearch();

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      tableHeight,
      tableDataSource,
      handleFormSearch,
      handleExport,
      schema: searchHeaderOptions,
      formatToDateTime
    };
  }
};
</script>

<style lang="less" scoped>
#serviceOrderBox {
  position: relative;
  overflow: hidden;
}
</style>
